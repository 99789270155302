document.documentElement.className = document.documentElement.className.replace(/\bno-js\b/g, "") + "js"

/**
 * Needed for SmoothScroll on page load
 * - prevents page jump on load
 */
if (window.location.hash) { setTimeout(function () { window.scrollTo(0, 0); }, 2); }

jQuery(document).ready(function ($) {

    /*-----------------------------------------------------------------------------GLOBAL ON LOAD----*/

    //Load images, and images after doc ready
    var lazyLoadImages = (function () {
        var $lazyImages = $('img[data-src], iframe[data-src], [data-background]');
        if (!$lazyImages.length) { return; }

        function init() {
            $lazyImages.each(function () {
                var $this = $(this),
                    delay = 0,
                    dataSrc;
                if (dataSrc = $this.data('background')) {
                    if ($this.data('background-delay')) {
                        delay = $this.data('background-delay');
                    }
                    setTimeout(function () {
                        $this.css('background-image', 'url(' + dataSrc + ')');
                        $this.attr('data-background', null);
                    }, delay);
                } else if (dataSrc = $this.data('src')) {
                    if ($this.data('src-delay')) {
                        delay = $this.data('src-delay');
                    }
                    setTimeout(function () {
                        $this.attr('src', dataSrc);
                        $this.attr('data-src', null);
                    }, delay);
                }
            });
        }
        init();
        return { init: init }
    }());

        /*Slick Sliders*/
        var heroSlider = (function () {

        if ($('.hero .hero-slides').length) {

            $section = $('.hero--slider');
            $sliders = $('.hero .hero-slides');
            $sliders.each(function () {

                $(this).slick({
                    dots: true,
                    arrows: false,
                    infinite: false,
                    slidesToShow: 1,
                    fade: true,
                    autoplay: true,
                    appendDots: $section.find('.slick-nav'),

                });

            });

        }

    }());

    var twoColumnSlider = (function () {

        if ($('.two-column-with-image .two-column__slider').length) {

            $section = $('.two-column-with-image');
            $sliders = $('.two-column-with-image .two-column__slider');
            $sliders.each(function () {

                $(this).slick({
                    dots: true,
                    arrows: false,
                    infinite: false,
                    slidesToShow: 1,
                    fade: true,
                    autoplay: true,
                    appendDots: $section.find('.slick-nav'),

                });

            });

        }

    }());

         var imageSlider = (function () {

        if ($('.image-slider').length) {

            $section = $('.main-slider');
            $sliders = $('.image-slider');
            $sliders.each(function () {

                $(this).slick({
                    dots: true,
                    arrows: true,
                    infinite: false,
                    slidesToShow: 1,
                    appendDots: $section.find('.slick-nav'),

                });

            });

        }

    }());

  var brandBlock = (function () {
        var $section = $('.brands-block');
        var $brandSlider = $section.find('.slider');
        var bpDesktop = 720;

        $section.each(function () {
        $brandSlider.slick({
            mobileFirst: true,
            arrows: false,
            dots: true,
            adaptiveHeight: true,
            slidesToShow: 2,
            slidesToScroll: 2,
            appendDots: $section.find('.slick-nav'),
            responsive: [{
                breakpoint: bpDesktop,
                settings: 'unslick'
            }]
        });
    });
    }());

  var businessCards = (function () {
        var $section = $('.business-block');
        var $businessCards = $section.find('.slider');
        var bpDesktop = 720;

        $section.each(function () {
        $businessCards.slick({
            mobileFirst: true,
            arrows: false,
            dots: true,
            adaptiveHeight: true,
            slidesToShow: 2,
            appendDots: $section.find('.slick-nav'),
            responsive: [{
                breakpoint: bpDesktop,
                settings: 'unslick'
            }]
        });
    });
    }());


    var jobCards = (function () {
        var $section = $('.jobs-block');
        var $jobCards = $section.find('.slider');
        var bpDesktop = 720;

        $section.each(function () {
        $jobCards.slick({
            mobileFirst: true,
            arrows: false,
            dots: true,
            adaptiveHeight: true,
            slidesToShow: 1,
            appendDots: $section.find('.slick-nav'),
            responsive: [{
                breakpoint: bpDesktop,
                settings: 'unslick'
            }]
        });

    });
    }());

        var openCards = (function () {
        var $section = $('.position-cards');
        var $jobCards = $section.find('.slider');
        var bpDesktop = 720;

        $section.each(function () {
        $jobCards.slick({
            mobileFirst: true,
            arrows: false,
            dots: true,
            adaptiveHeight: true,
            slidesToShow: 1,
            appendDots: $section.find('.slick-nav'),
            responsive: [{
                breakpoint: bpDesktop,
                settings: 'unslick'
            }]
        });

    });
    }());

        var infoCards = (function () {
        var $section = $('.info-cards');
        var $jobCards = $section.find('.slider');
        var bpDesktop = 720;

        $section.each(function () {
        $jobCards.slick({
            mobileFirst: true,
            arrows: false,
            dots: true,
            adaptiveHeight: true,
            slidesToShow: 1,
            appendDots: $section.find('.slick-nav'),
            responsive: [{
                breakpoint: bpDesktop,
                settings: 'unslick'
            }]
        });

    });
    }());

    //Global function to grab parameters from url
    var urlParams = (function (strParam) {
        var objParams = {};

        function init() {
            objParams = {};
            var arrURLParams = window.location.search.replace('?', '').split('&');
            arrURLParams.map(function (val) {
                var arrPair = val.split('=');
                objParams[arrPair[0]] = decodeURIComponent(arrPair[1]);
            });
        }
        init();
        return {
            get: function (strParam) { return objParams[strParam]; },
            pull: init
        }
    }());

    //Global function to smooth scroll inside pages
    var SmoothScroll = (function () {
        var $anchorLinks = $('a[href^="#"]');

        function goTo(hash) {
            if (hash === "") { return; }

            var $target = $(hash);
            var scrollPos = $target.offset().top;

            if (window.innerWidth >= 720) {
                scrollPos -= $('header').outerHeight();
            } else {
                scrollPos -= $('header').outerHeight() * 2;
            }

            $('html, body').stop().animate({
                'scrollTop': scrollPos
            }, 1200, 'swing', function () {
                window.location.hash = hash;
            });
        }

        if (window.location.hash) {
            setTimeout(function () {
                goTo(window.location.hash);
            }, 500);
        }

        if (!$anchorLinks.length) { return; }
        $anchorLinks.not('[href="#"]').not('[href="0"]').on('click', function (e) {
            e.preventDefault();
            goTo(this.hash);
        });

        return { to: goTo }
    }());

    //Global function to toggle simple accordions
    var Accordions = (function () {
        var $accordions = $('.accordion');
        if (!$accordions.length) { return; }

        $accordions.each(function () {
            $this = $(this).find('.accordion__trigger');
            if ($this.hasClass('active')) {
                $this.next().slideToggle(250);
            }
        });
        $(document).on('click', '.accordion__trigger', function () {
            var $this = $(this);
            $this.toggleClass('active');
            $this.next().slideToggle(250);
        });
    }());

    //
    var InputMasks = (function () {
        var $masks = $('[data-mask]');
        if (!$masks.length) { return; }

        $('[data-mask]').keyup(function (e) {
            switch (this.dataset.mask) {
                case 'phone':
                    var x = this.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                    console.log(x);
                    this.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
                    break;
                case 'ssn': {
                    var x = this.value.replace(/\D/g, '').match(/^(\d{0,3})(\d{0,2})(\d{0,4})/);
                    this.value = !x[2] ? x[1] : x[1] + '-' + x[2] + '-' + x[3];
                }
            }
        });
    }());

    //Plugin used for form validation
    var parselyOptions = {
        classHandler: function (parsleyField) {
            var $element = parsleyField.$element;
            if ($element.parent().hasClass('select-menu')) {
                return $element.parent();
            }
            return $element;
        },
        errorsContainer: function (parsleyField) {
            var $element = parsleyField.$element;
            var $fieldContainer = $element.closest('.form-field');
            if ($fieldContainer) {
                return $fieldContainer;
            }
        }
    };

  //Global function to set form state classes
    var formStates = (function () {
        $formInputs = $('form :input');
        if (!$formInputs.length) { return; }

        $formSelectMenus = $('.select-menu select, .ginput_container_select select');

        function isGFormInput($el) {
            return $el.hasClass('ginput_container') ? $el.parent() : $el;
        }

        function setFilled($input) {
            var $parent = isGFormInput($input.parent());

            $parent.addClass('filled');
        }

        function removeFilled($input) {
            var $parent = isGFormInput($input.parent());

            $parent.removeClass('filled');
        }

        function setFocused() {
            var $parent = isGFormInput($(this).parent());

            $parent.addClass('focused');
        }

        function removeFocused() {
            var $parent = isGFormInput($(this).parent());

            $parent.removeClass('focused');
        }

        function checkInput(e) {
            if (this.type == 'button' ||
                this.type == 'range' ||
                this.type == 'submit' ||
                this.type == 'reset') { return; }

            var $this = $(this);
            var $parent = $this.parent();
            var is_selectMenu = $parent.hasClass('select-menu') || $parent.hasClass('ginput_container_select');

            var $input = is_selectMenu ? $parent : $this;

            switch (this.type) {
                case 'select-one':
                case 'select-multiple':
                    if (this.value !== '') {
                        setFilled($input);
                    } else {
                        removeFilled($input);
                    }
                    break;
                default:
                    if (this.value) {
                        setFilled($input);
                    } else {
                        removeFilled($input);
                    }
                    break;
            }
        }

        $formInputs.each(checkInput);
        $formInputs.on('change', checkInput);
        $formInputs.on('focus', setFocused);
        $formInputs.on('blur', removeFocused);
        $formSelectMenus.on('focus', setFocused);
        $formSelectMenus.on('blur', removeFocused);

    }());

    //Global function top open / close lightboxes
    var PDMLightbox = (function () {
        //Lightbox reset - This lightbox is empty and present on all pages
        var $lightbox = $('.pdm-lightbox--reset');

        //it's content can be filled from various sources
        //after close, the content is wiped
        var $lightbox_content = $('.pdm-lightbox--reset .pdm-lightbox__content');

        $('body').on('click', '[data-lightbox-iframe],[data-lightbox-content],[data-lightbox-target],.lightbox-trigger', function (e) {

            e.preventDefault();

            var iframe = $(this).data('lightbox-iframe');

            if (iframe) {
                $lightbox_content.html('<div class="iframe-wrap">' + iframe + '</div>');
            } else {
                if ($(this).data('lightbox-content')) {
                    var content = $(this).data('lightbox-content');
                } else if ($(this).data('lightbox-target')) {
                    var target = $(this).data('lightbox-target');
                    var content = $('#' + target).html();
                } else {
                    var content = $(this).next('.lightbox-content').html();
                }
                $lightbox_content.html(content);
            }

            var classes = $(this).data('lightbox-classes');
            $lightbox.addClass('active').addClass(classes);

        });

        function closeLightbox($lightbox) {
            $lightbox.removeClass('active');
            $('body').removeClass('noScroll');
            //wait before removing classes till lightbox closses
            if ($lightbox.hasClass('pdm-lightbox--reset')) {
                setTimeout(function () {
                    $lightbox.find('.pdm-lightbox__content').empty();
                    $lightbox.attr('class', 'pdm-lightbox pdm-lightbox--reset');
                }, 750);
            }
        }

        $('.pdm-lightbox').on('click', function (e) {
            var $lightbox = $(this);
            if (e.target == this) {
                closeLightbox($lightbox);
            }
        });

        $('.pdm-lightbox__close').click(function (e) {
            e.stopPropagation();
            $lightbox = $(this).closest('.pdm-lightbox');
            closeLightbox($lightbox);
        });
        return {
            close: closeLightbox
        };
    }());

    var Header = (function () {
        var $header = $('header.global-header');
        var $nav = $header.find('.menu-popup');

        var BurgerMenu = (function () {
            var $burgerMenu = $header.find('.menu-burger');

            function activate() {
                $burgerMenu.addClass('active').attr('title', 'Close');
                $nav.addClass('active');
            }

            function reset() {
                $burgerMenu.removeClass('active').attr('title', 'Menu');
                $nav.removeClass('active');
            }

            $burgerMenu.click(function () {
                var $this = $(this);

                if ($this.hasClass('active')) { reset(); } else { activate(); }
            });
        }());

    }());

    var loadMorePosts = (function () {
        var $loadMoreButton = $('.load-more-btn');
        if (!$loadMoreButton.length) { return; }

        var $loadMore = $('.load-more-container');
        var $loadMoreContainer = $loadMore.find('.container');

        function ajaxPosts(target, replace) {
            $.ajax({
                type: 'GET',
                dataType: 'html',
                url: target,
                success: function (response) {
                    //                  console.log(response);
                    if (replace == true) {
                        $loadMoreContainer.html($(response).find('.load-more-container').html());
                    } else {
                        $loadMoreContainer.append($(response).find('.load-more-container article'));
                    }

                    if ($(response).find('.load-more-btn').length) {
                        if ($loadMoreButton.length) {
                            $loadMoreButton.html($(response).find('.load-more-btn').html());
                        }
                    } else {
                        if ($loadMoreButton.length) {
                            $loadMoreButton.remove();
                        }
                    }

                    lazyLoadImages.init();
                    setTimeout(function () {
                        $loadMore.removeClass('loading');
                        lazyLoadImages.init();
                    }, 250);
                }
            });
        }
        $loadMore.on('click', '.load-more-btn a', function (e) {
            e.preventDefault();
            $(this).addClass('loading');
            var target = $(this).attr('href');
            ajaxPosts(target, false);

        });

    }());

    //----------------------------------------------

//Tabs

//---------------------------------------------

var firstTabMobile = $('.tabs').find('.tab-link').first().html();
var firstContent   = $('.tabs').find('.tab-item').first().html();
var firstLink      = $('.tabs').find('.tab-link').first().addClass('active');
var clickAllowed   = false;

$('.tab-content').append(firstContent);

//For Mobile
$('.tabs-btn').append(firstTabMobile);
$('.tabs-btn').click(function() {
    $('.tabs').slideToggle();
});

if ($(window).width() < 960) {
    clickAllowed = true;
    $('.tabs').hide();
    $('footer nav ul').hide();
}
tabsMobile = function() {
    //Needs to fire on Resize
     if ($(window).width() < 960) {
        clickAllowed = true;
        $('.tabs').hide();
        $('.dropdown-mobile').hide();
        $('footer nav ul').hide();
        $('footer .heading').removeClass('active');
     } else {
        clickAllowed = false;
        $('.tabs').show();
        $('.dropdown-mobile').css('display', 'flex');
        $('footer nav ul').show();
     }

}

/*Tab link CLicking*/
$(".tab-link").click(function() {
    $('.tab-content').empty();
    $('.tab-link').removeClass('active');
    $(this).addClass('active');

  

    var content =  $(this).closest(".tab").find(".tab-item").html();
     $('.tab-content').append(content);
     $('.tab-content').hide().fadeIn();

     /*Mobile*/
    if(clickAllowed) {
        var content = $(this).html();
        $('.tabs-btn').empty();
        $('.tabs-btn').append(content);
        $('.tabs').slideToggle();
    }
});

$(window).bind('resize', tabsMobile);


});